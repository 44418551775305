import { defineStore } from 'pinia';
import axios, { AxiosError } from 'axios';
import { getAutoDiscoveredEndpoint } from './composables/getAutodiscoveredEndpoint';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import { Ref } from 'vue';

interface LoginResponse {
	token: string;
}

type ClientMap = Readonly<
	Record<
		string,
		{
			endpoint: Readonly<string>;
			username: Readonly<string>;
		}
	>
>;

export const clientsRefDefault =
	'{"default": {"endpoint": "/api", "username": ""}}';
export const settings: {
	clientsRef: Ref<string>;
	currentClientNameRef: Ref<string>;
} = {
	clientsRef: useLocalStorage('pinia/connection/clientsRef', clientsRefDefault),
	currentClientNameRef: useLocalStorage(
		'pinia/connection/currentClientNameRef',
		'default',
	),
};

const clientsRef = computed<ClientMap>({
	get: () => {
		const jsonRes = JSON.parse(settings.clientsRef.value) as ClientMap;
		return Object.keys(jsonRes).reduce((res, cn) => {
			const c = jsonRes[cn];
			return {
				...res,
				[cn]: {
					endpoint: c.endpoint,
					username: c.username,
				},
			};
		}, {});
	},
	set(v) {
		settings.clientsRef.value = JSON.stringify(
			Object.keys(v).reduce((res, cn) => {
				const c = v[cn];
				return {
					...res,
					[cn]: {
						endpoint: c.endpoint,
						username: c.username,
					},
				};
			}, {}),
		);
	},
});

export const useConnectionStore = defineStore('connection', {
	state: () => ({
		currentClientName: settings.currentClientNameRef,
		clients: clientsRef,
		_token: null as null | string,
		pendingRefresh: null as null | Promise<{
			ok: boolean;
			errMsg: string | null;
		}>,
		autoDiscoveredEndpoints: {} as Record<string, string>,
	}),
	actions: {
		async login(
			username: string,
			password: string,
			endpoint?: string,
			token?: string,
		) {
			let ok = false;
			let errMsg: string | null = null;

			if (endpoint === undefined)
				endpoint = this.clients[this.currentClientName].endpoint;

			endpoint = await getAutoDiscoveredEndpoint(endpoint);
			const credentials = token
				? { username, password, token }
				: { username, password };

			try {
				const res = await axios.post<LoginResponse>(
					endpoint + '/auth/login',
					credentials,
					{ withCredentials: true },
				);
				this._token = res.data.token;
				ok = true;
			} catch (e) {
				errMsg =
					((e as AxiosError).response?.data as { error: string } | undefined)
						?.error || 'unknown_login_error';
			}

			if (ok) {
				this.clients = {
					...this.clients,
					[this.currentClientName]: {
						endpoint,
						username,
					},
				};
			}

			return { ok, errMsg };
		},
		async logout() {
			let errMsg: string | null = null;
			try {
				await axios.post<LoginResponse>(
					this.clients[this.currentClientName].endpoint + '/auth/logout',
					undefined,
					{ withCredentials: true },
				);
				this._token = null;
			} catch (e) {
				errMsg =
					((e as AxiosError).response?.data as { error: string } | undefined)
						?.error || 'unknown_login_error';
			}
			return { errMsg };
		},
		refresh() {
			// if a refresh is already happening, return that Promise
			if (this.pendingRefresh) return this.pendingRefresh;

			const f = async () => {
				let ok = false;
				let errMsg: string | null = null;
				try {
					const res = await axios.post<LoginResponse>(
						this.clients[this.currentClientName].endpoint + '/auth/refresh',
						undefined,
						{ withCredentials: true },
					);
					this._token = res.data.token;
					ok = true;
				} catch (e) {
					errMsg =
						((e as AxiosError).response?.data as { error: string } | undefined)
							?.error || 'unknown_login_error';
				}
				return { ok, errMsg };
			};

			// store pending refresh
			this.pendingRefresh = f();

			// and clear it when it's done
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.pendingRefresh!.finally(() => {
				this.pendingRefresh = null;
			});

			return this.pendingRefresh;
		},
		createClient(name: string) {
			this.clients = {
				...this.clients,
				[name]: { endpoint: '/api', username: '' },
			};
			this.currentClientName = name;
		},
		switchClient(name: string) {
			if (!Object.keys(this.clients).includes(name)) return;
			this.currentClientName = name;
			return this.refresh();
		},
		removeConnection(name: string) {
			if (name === this.currentClientName) return;
			const c = { ...this.clients };
			delete c[name];
			this.clients = c;
		},
	},
	getters: {
		getEndpoint(): string {
			return this.clients[this.currentClientName].endpoint;
		},

		getPayload() {
			// No token, is no token
			if (!this._token) return null;

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const parts = this._token!.split('.');

			// Not a valid jwt format
			if (parts.length !== 3) return null;

			const raw = decodeURIComponent(escape(atob(parts[1])));
			return JSON.parse(raw);
		},

		getToken(): () => string | null {
			const payload = this.getPayload;
			if (!payload) return () => null;

			// Not a valid payload (misses exp or malformed exp)
			if (!payload.exp || typeof payload.exp != 'number') return () => null;

			const exp = fromUnixTime(payload.exp);
			return () => {
				// If there are less than 30 seconds left, invalidate in case of wrong pc time
				if (differenceInSeconds(exp, new Date()) < 30) return null;

				return this._token;
			};
		},

		getFullName(): string {
			const payload = this.getPayload;
			if (!payload) return '';
			return payload.full_name;
		},

		getRelationId(): string {
			const payload = this.getPayload;
			if (!payload) return '';
			return payload.relation_id;
		},

		isAuthenticated() {
			return () => !!this.getToken();
		},
	},
});
